.InputGroup:focus-within {
  border-color: var(--color-primary);
}

.InputGroup:focus-within svg {
  color: var(--color-primary);
}

.InputGroup__error {
  border-color: var(--color-red-error);
}
